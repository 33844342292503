
import { defineComponent } from 'vue';

import ReminderListsSelect from '@/components/forms/components/ReminderListsSelect.vue';
import Navigator from '@/views/new-design/layout/Navigator.vue';

import { Actions, Getters, Mutations } from '@/store/enums/ReminderEnums';
import store from '@/store';
import { mapActions, mapGetters } from 'vuex';

const systemName = '' as string;

export default defineComponent({
  name: 'reminder-settings-page',
  components: {
    ReminderListsSelect,
    Navigator,
  },
  data: () => ({
    options: [] as unknown as [],
    reminderTo: systemName,
    currentTemplateType: '',
    loading: false,
    showForm: false,
  }),

  mounted() {
    this.initializeForm();
  },

  computed: {
    ...mapGetters({
      errors: Getters.GET_ACTION_ERROR,
      reminders: Getters.GET_REMINDERS,
      reminderLists: Getters.GET_REMINDER_LIST,
      placeholders: Getters.GET_PLACEHOLDERS,
    }),
  },
  methods: {
    ...mapActions({
      fetchReminderByDay: Actions.FETCH_REMINDER_BY_DAY,
      fetchRemindersByName: Actions.FETCH_REMINDERS_BY_NAME,
      fetchPlaceholders: Actions.FETCH_PLACEHOLDERS,
    }),

    initializeForm() {
      const templateType = this.$route.meta.template;
      const { template } = this.$route.query;
      this.currentTemplateType = templateType as string;

      if (typeof template !== 'undefined') {
        this.reminderTo = template as string;
      }

      store.commit(Mutations.SET_REMINDER, {});
      store.commit(Mutations.SET_REMINDERS, {});
    },

    handleReminderListChange(value) {
      this.loading = true;

      this.reminderTo = value;

      store.commit(Mutations.SET_REMINDER, {});
      store.commit(Mutations.SET_REMINDERS, {});

      if (typeof this.reminderTo !== 'undefined') {
        this.fetchPlaceholders(this.reminderTo);
      }

      this.$router.push({
        path: '',
        query: { template: this.reminderTo },
      });

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },

    isActive(path) {
      return this.$route.path.includes(path);
    },
  },
  watch: {
    $route: {
      handler(value) {
        if (typeof value.query.template === 'undefined') {
          this.reminderTo = '';
          this.showForm = false;
        }
      },
      immediate: true,
    },
  },
});
